// extracted by mini-css-extract-plugin
export const mainDiv = "videoindex-module--main-div--eCm7v";
export const divOne = "videoindex-module--div-one--rIhxe";
export const promptButtons = "videoindex-module--prompt-buttons--2kERy";
export const divTwo = "videoindex-module--div-two--1QypW";
export const boxesDiv = "videoindex-module--boxes-div--pXHXT";
export const pointBox = "videoindex-module--point-box--1ueWk";
export const svgBox = "videoindex-module--svg-box--2fdKA";
export const title = "videoindex-module--title--8MmNc";
export const subtitle = "videoindex-module--subtitle--76MPa";
export const divThree = "videoindex-module--div-three--3Hiu8";
export const benefitsList = "videoindex-module--benefits-list--36hWP";
export const divFour = "videoindex-module--div-four--3-n0D";
export const howToBox = "videoindex-module--how-to-box--2y9iX";
export const videoWrapper = "videoindex-module--video-wrapper--346S1";
export const divFive = "videoindex-module--div-five--1h40p";
export const fiveInner = "videoindex-module--five-inner--U05Nd";
export const shapesDiv = "videoindex-module--shapes-div--2JBOB";
export const rectangle = "videoindex-module--rectangle--21nJS";
export const circle = "videoindex-module--circle--2FvMO";
export const square = "videoindex-module--square--2mLBw";
export const smallCircle = "videoindex-module--small-circle--3ea1a";