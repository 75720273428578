import React from "react";
import Layout from "../../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import VideoBackground1 from "../../assets/svg/video-background-1.svg";
import RocketSvg from "../../assets/svg/rocket.svg";
import TrafficSvg from "../../assets/svg/traffic.svg";
import AudienceSvg from "../../assets/svg/audience.svg";
import VideoStep1Svg from "../../assets/svg/video-step-1.svg";
import VideoStep2Svg from "../../assets/svg/video-step-2.svg";
import VideoStep3Svg from "../../assets/svg/video-step-3.svg";
import { Link } from "gatsby";
import * as styles from "../../styles/videoindex.module.scss";
import { Helmet } from "react-helmet";

const PointsBoxes = (props) =>
  props.data.map((item, index) => {
    return (
      <div className={styles.pointBox}>
        <div className={styles.svgBox}>{item.image}</div>
        <span className={styles.title}>{item.title}</span>
        <span className={styles.subtitle}>{item.subtitle}</span>
      </div>
    );
  });

const HowToBoxes = (props) =>
  props.data.map((item, index) => {
    return (
      <div className={styles.howToBox}>
        <div>{item.image}</div>
        <div>
          <span>
            {index + 1}. {item.title}
          </span>
          <span>{item.subtitle}</span>
        </div>
      </div>
    );
  });

export default () => {
  return (
    <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AppStore Video Preview | Generator | Create an awesome video</title>
                <meta name="description" content="Create a promo video that your app deserves, in just a few clicks. Works perfectly for AppStore and GooglePlay." />
            </Helmet>
      <div className={styles.mainDiv}>
        <div className={styles.divOne}>
          <div>
            <span>DO-IT-YOURSELF</span>
            <h1>Promo video for your App in a few clicks (alpha)</h1>
            <h2>
              Create stunning promo previews using our video maker in minutes. Perfect for iOS and Android apps.
            </h2>
            <div className={styles.promptButtons}>
              <Link to={"/video/maker"}>
                <button>Start Now</button>
              </Link>
              <Link to={"#video"}>
                <button>Watch a video tutorial</button>
              </Link>
            </div>
          </div>
          <div>
            <div>
            <StaticImage
              src="../../assets/images/video-img-1.png"
              alt="Generate Videos for App Store and Google Play"
              placeholder="blurred"
              layout="constrained"
              width={1200}
            />
            </div>
          </div>
        </div>
        <div className={styles.divTwo}>
          <VideoBackground1 />
          <div>
            <div className={styles.boxesDiv}>
              <PointsBoxes
                data={[
                  {
                    image: <RocketSvg />,
                    title: "Boost your App",
                    subtitle:
                      "Stand out from the competetion and launch your app to the world.",
                  },
                  {
                    image: <TrafficSvg />,
                    title: "Improve Metrics",
                    subtitle:
                      "Increase CTR, downloads and conversions for your app.",
                  },
                  {
                    image: <AudienceSvg />,
                    title: "Engage Your Audience",
                    subtitle:
                      "Nothing explains your app better than a short video preview.",
                  },
                ]}
              />
            </div>
            <Link to={"/video/maker"}>
              <button>Start creating, it's free!</button>
            </Link>
          </div>
        </div>
        <div className={styles.divThree}>
          <div>
          <StaticImage
              src="../../assets/images/video-img-2.png"
              alt="Generate Videos for App Store and Google Play"
              placeholder="blurred"
              layout="constrained"
              width={1200}
            />
          </div>
          <div>
            <h1>Our Awesome Features</h1>
            <ul className={styles.benefitsList}>
              <li>
                <span>Fast.</span> Tasks that previously taken hours are reduced to
                just a few minutes.
              </li>
              <li>
                <span>Simple.</span> No install. No passwords. No technical
                tutorials. Just ease of use.
              </li>
              <li>
                <span>Accessible.</span> Available from all devices, in a web browser.
              </li>
              <li>
                <span>Modern.</span> Today's tools are stuck in the past.
                <br />
              </li>
              <li>
              <span>Previewed</span> was made by today's creators, for today's creators.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.divFour}>
          <div>
            <h1>Make a video in 3 easy steps</h1>
            <div className={styles.boxesDiv}>
              <HowToBoxes
                data={[
                  {
                    image: <VideoStep1Svg />,
                    title: "Add Media",
                    subtitle: "Add videos from your devices to the storyboard.",
                  },
                  {
                    image: <VideoStep2Svg />,
                    title: "Customize Slides",
                    subtitle:
                      "Add transition slides, text, background and more to customize your video.",
                  },
                  {
                    image: <VideoStep3Svg />,
                    title: "Export",
                    subtitle:
                      "Download your video and share it with the world.",
                  },
                ]}
              />
            </div>
            <div className={styles.videoWrapper} id="video">
              <iframe width="560" height="349" src="https://www.youtube.com/embed/LGCcIsCDGkU" frameborder="0" allowfullscreen></iframe>
            </div>
            <Link to={"/video/maker"}>
              <button>Create a Video</button>
            </Link>
          </div>
        </div>

        <div className={styles.divFive}>
          <div className={styles.fiveInner}>
            <h1>Start editing and creating video today</h1>
            <h3>Make your app previews awesome. Fast. Easy.</h3>
            <Link to={"/video/maker"}>
              <button>Create a video, it's free!</button>
            </Link>
          </div>
          <div className={styles.shapesDiv}>
            <div
              className={styles.rectangle}
              style={{ top: "-10%", left: "20%" }}
            />
            <div className={styles.circle} style={{ top: "80%", left: "0%" }} />
            <div
              className={styles.square}
              style={{ top: "60%", left: "15%" }}
            />
            <div
              className={styles.smallCircle}
              style={{ top: "80%", left: "35%" }}
            />

            <div
              className={styles.rectangle}
              style={{ top: "80%", left: "80%" }}
            />
            <div
              className={styles.circle}
              style={{ top: "-15%", left: "70%" }}
            />
            <div
              className={styles.square}
              style={{ top: "40%", left: "65%" }}
            />
            <div
              className={styles.smallCircle}
              style={{ top: "80%", left: "70%" }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
